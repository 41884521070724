import hostImage from "../assets/img/services/host.jpg";
import advogramImage from "../assets/img/services/advogram.jpg";
import dvostokImage from "../assets/img/services/dvostok.jpg";
import notaryImage from "../assets/img/services/notary.jpg";
import copyrImage from "../assets/img/services/copyr.jpg";
import jobImage from "../assets/img/services/job.jpg";

const services = [
  {
    title: "Website Hosting Solutions with h4w",
    text: "Get top-notch hosting services, even during challenging times.",
    img: hostImage,
    link: "https://proj-associo.pages.dev/",
  },
  {
    title: "Legal Consultation with Advogram",
    text: "Get fast and convenient legal consultation online.",
    img: advogramImage,
    link: "https://proj-associo.pages.dev/",
  },
  {
    title: "Transfers Tilda sites with Dvostok",
    text: "We'll help migrate your site from Tilda to WordPress.",
    img: dvostokImage,
    link: "https://proj-associo.pages.dev/",
  },
  {
    title: "Notary Services with E-notarius",
    text: "Get legal services and consultations online.",
    img: notaryImage,
    link: "https://proj-associo.pages.dev/",
  },
  {
    title: "Secure copyright with Vdsvault",
    text: "We provide proof of authorship that wins cases.",
    img: copyrImage,
    link: "https://proj-associo.pages.dev/",
  },
  {
    title: "Job search with NGO Recruitment agency",
    text: "Our solution will help you be confident in the future and calmly do what you love.",
    img: jobImage,
    link: "https://proj-associo.pages.dev/",
  },
];

export default services;
