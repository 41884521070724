import React from 'react';
import img01 from "../assets/img/svg/Frame-1.svg"
import img02 from "../assets/img/svg/Frame-2.svg"
import img03 from "../assets/img/svg/Frame-3.svg"
import img04 from "../assets/img/svg/Frame-4.svg"
import img05 from "../assets/img/svg/Frame-5.svg"
import img06 from "../assets/img/svg/Frame-6.svg"
import img07 from "../assets/img/svg/Frame-7.svg"
import img08 from "../assets/img/svg/Frame-8.svg"
import img09 from "../assets/img/svg/Frame-9.svg"
import img10 from "../assets/img/svg/Frame-10.svg"
import { LazyLoadImage } from "react-lazy-load-image-component"

const DecorElement = () => {
  return (
    <div className="flex jcfs aic my-5 m-block sign">
              <LazyLoadImage
                width={64}
                height={64}
                src={img01}
                alt="feature"
                className="mx-1 border rounded r-32"
              />
              <LazyLoadImage
                width={64}
                height={64}
                src={img02}
                alt="feature"
                className="mx-1 border rounded r-32"
              />
              <LazyLoadImage
                width={64}
                height={64}
                src={img03}
                alt="feature"
                className="mx-1 border rounded r-32"
              />
              <LazyLoadImage
                width={64}
                height={64}
                src={img04}
                alt="feature"
                className="mx-1 border rounded r-32"
              />
              <LazyLoadImage
                width={64}
                height={64}
                src={img05}
                alt="feature"
                className="mx-1 border rounded r-32"
              />
              <LazyLoadImage
                width={64}
                height={64}
                src={img06}
                alt="feature"
                className="mx-1 border rounded r-32"
              />
              <LazyLoadImage
                width={64}
                height={64}
                src={img07}
                alt="feature"
                className="mx-1 border rounded r-32"
              />
              <LazyLoadImage
                width={64}
                height={64}
                src={img08}
                alt="feature"
                className="mx-1 border rounded r-32"
              />
              <LazyLoadImage
                width={64}
                height={64}
                src={img09}
                alt="feature"
                className="mx-1 border rounded r-32"
              />
              <LazyLoadImage
                width={64}
                height={64}
                src={img10}
                alt="feature"
                className="mx-1 border rounded r-32"
              />
            </div>
  )
}

export default DecorElement

