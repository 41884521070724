import Globe from '../assets/img/benefits/globe.png';
import Megaphone from '../assets/img/benefits/megaphone.png';
import Books from '../assets/img/benefits/books.png';
import Bonnet from '../assets/img/benefits/bonnet.png';
import Face from '../assets/img/benefits/face.png';
import Hands from '../assets/img/benefits/hands.png';

const benefitsFP = [
  {
    id: 1,
    img: Globe,
    title: "Global communication:",
    text: "Expand your professional connections by communicating with world-class colleagues",
  },
  {
    id: 2,
    img: Hands,
    title: "Network of experts:",
    text: "Build your network with leading professionals and the best like-minded people in your field.",
    
  },
  {
    id: 3,
    img: Bonnet,
    title: "Professional development:",
    text: "Unlock your potential through webinars, certifications, and learning resources.",
   
  },
  {
    id: 4,
    img: Face,
    title: "Expert advice:",
    text: "Get advice from leading experts or give advice to others.",
  },
  {
    id: 5,
    img: Books,
    title: "Exclusive resources:",
    text: "Get access to unique research, tools, and materials in your field.",
    
  },
  {
    id: 6,
    img: Megaphone,
    title: "Impact on the industry:",
    text: "Shape your future profession, participate in important industry discussions and initiatives.",
  },
];

export default benefitsFP;
