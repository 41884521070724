import { CardBlog } from "../elements/CardBlog";

const Stories = () => {
  return (
    <section className="cases">
      <div className="container flex col jcc aic">
        <div className="stories">
          <h2 className="title-ass mnl">Blog and our stories</h2>
          <div className="button btn-cases">
            <a href="/blog" target="blank">
              All Cases
            </a>
          </div>
        </div>

        <h3 className="title-accent mis">
          Explore our latest articles and insights
        </h3>
        <CardBlog />
      </div>
    </section>
  );
};

export { Stories };
