import missions from "../data/missions";

const Mission = () => {
  return (
    <ul className="about-mission">
      {missions.map((mission, index) => (
        <li key={index} className="item-mission" id={mission.id}>
          <h3 className="mission-title">{mission.title}</h3>
          <p className="mission-text">{mission.text}</p>

          <img
            src={mission.img}
            alt="Mission"
            loading="lazy"
            className="mission-img"
          ></img>
          <div className="mission-decor">
            <img src={mission.decor} alt="Decor"></img>
          </div>
        </li>
      ))}
    </ul>
  );
};

export { Mission };
