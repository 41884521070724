// import { HiRocketLaunch, HiFlag } from 'react-icons/hi2';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
// import { Link } from 'react-router-dom';
// import pic from '../../assets/img/bgs/bm.webp';
// import DecorElement from '../../elements/DecorElement';

const About = () => {
  // return (
  //   <section>
  //     <div className="container">
  //       <div className="s-100"></div>
  //       <div className="row">
  //         <div className="col-lg-10">
  //           <div className="s-20"></div>
  //           <DecorElement />
  //           <div className="s-40"></div>
  //         </div>
  //       </div>
  //     </div>
  //     <div className="container">
  //       <div className="row">
  //         <div className="col-lg-12">
  //           <h1 className="title-big tc">Про Нас</h1>
  //           <div className="s-40"></div>
  //           <p className="title-part">
  //             На порозі нового тисячоліття, сектор інформаційних технологій
  //             продовжує зростати експоненційними темпами, відкриваючи безмежні
  //             можливості для інновацій, розвитку та соціального впливу. Однак,
  //             цей бурхливий розвиток породжує виклик, який не можна ігнорувати -
  //             високий рівень безробіття серед молодих IT-спеціалістів, які щойно
  //             закінчили навчальні заклади. Відсутність реального робочого
  //             досвіду стає каменем преткнення на шляху до їхнього першого
  //             працевлаштування в цій динамічній галузі.
  //           </p>
  //           <p className="title-part">
  //             Ми - це спільнота, яка прагне перетворити цей виклик на
  //             можливість. Наша місія полягає у забезпеченні молодих
  //             IT-спеціалістів необхідним реальним робочим досвідом через участь
  //             у соціально значущих проектах, що відбувається в умовах повної
  //             симуляції робочого середовища IT-компанії. Ми надаємо безкоштовну
  //             платформу для навчання, взаємодії та професійного розвитку,
  //             допомагаючи нашим учасникам покращити свої навички, побудувати
  //             міцне портфоліо та зробити перший крок на шляху до успішної
  //             кар'єри в IT.
  //           </p>
  //           <p className="title-part">
  //             Через наші проекти, ми не лише підтримуємо професійний розвиток
  //             кожного учасника, а й робимо внесок у вирішення важливих
  //             соціальних проблем, спільно працюючи над проектами, які мають
  //             реальний вплив на життя громади. Ми віримо, що кожен здатен внести
  //             вклад у покращення світу навколо, і надаємо необхідні ресурси для
  //             того, щоб зробити це можливим.
  //           </p>
  //         </div>
  //       </div>
  //     </div>
  //     <div className="bg-white py-5">
  //       <div className="s-40"></div>
  //       <div className="container-fluid">
  //         <div className="row">
  //           <div className="col-lg-5  h-50">
  //             <div className="flex m-block">
  //               <span
  //                 className="w-20 flex jcc aic r-16 bg-theme mx-4 m-icon"
  //                 style={{ height: 96 }}
  //               >
  //                 <HiRocketLaunch size={32} color={'white'} />
  //               </span>
  //               <div className="mx-4 flex aic">
  //                 <p>В Topizda ми віримо в силу реального досвіду.</p>
  //               </div>
  //             </div>
  //             <div className="s-20"></div>
  //             <div className="flex m-block">
  //               <span
  //                 className="w-20 flex jcc aic r-16 bg-marine mx-4 m-icon"
  //                 style={{ height: 96 }}
  //               >
  //                 <HiFlag size={32} color={'white'} />
  //               </span>
  //               <div className="mx-4">
  //                 <p>
  //                   Наші проекти створені для того, щоб надати молодим
  //                   спеціалістам можливість працювати над реальними завданнями,
  //                   які приносять користь суспільству.
  //                 </p>
  //               </div>
  //             </div>
  //             <div className="s-20"></div>
  //           </div>
  //           <div className="col-lg-7  h-40">
  //             <LazyLoadImage src={pic} alt="bm" className="h-40" />
  //           </div>
  //         </div>
  //       </div>
  //       <div className="s-40"></div>
  //     </div>
  //     <div className="container">
  //       <div className="row">
  //         <div className="col-lg-12">
  //           <h2 className="title-big tc">Наша Mісія</h2>
  //           <div className="s-30"></div>
  //           <p className="title-part pt-4 pb-4 px-4 r-24 bg-light">
  //             Місія нашої організації проста, але грандіозна - зменшити рівень
  //             безробіття серед молодих IT-спеціалістів, надаючи їм можливість
  //             отримати реальний робочий досвід через участь у соціально значущих
  //             проектах. Ми розуміємо, що перший крок у професійному світі IT
  //             може бути вкрай важким, особливо коли більшість роботодавців шукає
  //             кандидатів із досвідом. Наша програма розроблена так, щоб
  //             заповнити цей розрив, надаючи молодим спеціалістам платформу для
  //             практичного вдосконалення своїх навичок в реальному робочому
  //             середовищі.
  //           </p>
  //           <p className="title-part pt-4 pb-4 px-4 r-24 bg-light">
  //             Ми віримо, що реальний робочий досвід є ключем до успішного
  //             трудовлаштування в галузі IT, і саме тому ми створили умови, які
  //             максимально наближені до реального робочого середовища
  //             IT-компаній. Наші учасники працюють над актуальними проектами, які
  //             вирішують реальні соціальні проблеми, під керівництвом досвідчених
  //             менторів, які допомагають їм вдосконалювати свої навички, розуміти
  //             вимоги ринку праці та підготуватися до викликів, які вони
  //             зіткнуться у своїй кар'єрі.
  //           </p>
  //           <p className="title-part pt-4 pb-4 px-4 r-24 bg-light">
  //             Ми також прагнемо позитивного соціального впливу через наші
  //             проекти. Кожен проект, над яким працюють наші учасники, має
  //             соціальну направленість та спрямований на поліпшення життя
  //             громади. Ми обираємо проекти, які мають потенціал зробити реальну
  //             різницю в життях людей, і надаємо нашим учасникам можливість
  //             бачити безпосередній вплив їхньої роботи на світ навколо.
  //           </p>
  //           <p className="title-part pt-4 pb-4 px-4 r-24 bg-light">
  //             Наш підхід безкоштовний для всіх учасників. Ми віримо, що
  //             фінансові обмеження не повинні перешкоджати талановитим молодим
  //             людям робити свій внесок у суспільство та розвивати свою кар'єру.
  //             Наша спільнота відкрита для всіх, хто має пристрасть до технологій
  //             та бажання зробити світ кращим через свої навички та знання.
  //           </p>
  //           <div className="s-30"></div>
  //         </div>
  //       </div>
  //     </div>
  //     <div className="container">
  //       <div className="row">
  //         <div className="col-lg-12">
  //           <h2 className="title-big tc">Як це працює:</h2>
  //           <div className="s-30"></div>
  //           <p className="title-part">
  //             Перехід від академічного навчання до реального робочого середовища
  //             може бути вкрай стрімким. Наша організація намагається полегшити
  //             цей перехід, надаючи учасникам можливість працювати над реальними
  //             проектами в командному середовищі, що симулює робоче середовище
  //             IT-компанії. Ось як це працює:
  //           </p>
  //           <div className="s-20"></div>
  //           <ol className="pt-4 pb-4 px-5 r-24 bg-light">
  //             <li className="mb">
  //               <span className="title-sub">Реєстрація та оцінювання:</span>
  //               <br />
  //               Після реєстрації учасники приєднуються до команд, які працюють
  //               над актуальними соціально значущими проектами. Це надає їм
  //               можливість відчути справжнє робоче середовище та відпрацювати
  //               важливі навички командної роботи.
  //             </li>
  //             <li className="mb">
  //               <span className="title-sub mb">Приєднання до проекту:</span>
  //               <br />
  //               Кожен бажаючий починає з реєстрації на нашому сайті, де ми
  //               оцінюємо базові технічні навички та визначаємо, як найкраще
  //               можна допомогти учасникам розвинути свої навички.
  //             </li>
  //             <li className="mb">
  //               <span className="title-sub">Менторська підтримка</span>
  //               <br />
  //               На кожному етапі проекту наші учасники отримують підтримку від
  //               досвідчених менторів, які допомагають їм розуміти завдання,
  //               подолати виклики та вдосконалювати свої навички.
  //             </li>
  //             <li className="mb">
  //               <span className="title-sub">
  //                 Реальні проекти, реальний вплив:
  //               </span>
  //               <br />
  //               Учасники працюють над проектами, які мають реальний соціальний
  //               вплив, допомагаючи вирішувати важливі проблеми суспільства. Це
  //               не тільки покращує їх резюме, але й допомагає відчути
  //               задоволення від внесеного вкладу в суспільство.
  //             </li>
  //             <li className="mb">
  //               <span className="title-sub">Відбірковий процес:</span>
  //               <br />
  //               Після завершення проекту, ми допомагаємо учасникам підготуватися
  //               до працевлаштування, надаючи рекомендації, відгуки та підтримку
  //               у пошуку роботи в галузі IT.
  //             </li>
  //             <li className="mb">
  //               <span className="title-sub">Безкоштовна участь:</span>
  //               <br />
  //               Весь процес від реєстрації до працевлаштування абсолютно
  //               безкоштовний для учасників. Ми прагнемо зробити вхід в галузь IT
  //               доступним для всіх талановитих і мотивованих осіб, незважаючи на
  //               їхні фінансові можливості.
  //             </li>
  //           </ol>
  //           <div className="s-20"></div>
  //           <p className="title-part">
  //             Ми прагнемо створити середовище, де кожен мотивований і
  //             талановитий індивід може набути необхідного досвіду для початку
  //             своєї кар'єри в галузі IT, одночасно роблячи важливий внесок у
  //             соціальний розвиток своєї громади.
  //           </p>
  //         </div>
  //       </div>
  //     </div>
  //     <div className="container">
  //       <div className="row">
  //         <div className="col-lg-12">
  //           <div className="s-50"></div>
  //           <h2 className="title-big tc">Наші Проекти:</h2>
  //           <div className="s-30"></div>
  //           <p className="title-part">
  //             Наша організація створює мост між академічними знаннями та
  //             реальним робочим досвідом через реалізацію соціально значущих
  //             проектів. Ці проекти не просто допомагають нашим учасникам
  //             отримати цінний досвід, але й сприяють позитивним змінам в
  //             суспільстві. Ось декілька ключових аспектів наших проектів:
  //           </p>
  //           <div className="s-20"></div>
  //           <ul className="pt-4 pb-4 px-5 r-24 bg-light">
  //             <li className="mb">
  //               <span className="title-sub">Соціальна Орієнтація:</span>
  //               <br />
  //               Кожен наш проект має ясно визначену соціальну мету. Ми віримо,
  //               що технології можуть бути потужним інструментом для покращення
  //               життя людей, і ми вибираємо проекти, які мають потенціал зробити
  //               реальний вплив на наше суспільство.
  //             </li>
  //             <li className="mb">
  //               <span className="title-sub">Практичний Досвід:</span>
  //               <br />
  //               Робота над реальними проектами дозволяє нашим учасникам отримати
  //               практичний досвід, який вони можуть вказати у своєму резюме. Це
  //               включає розробку, тестування, управління проектами, спілкування
  //               з командою та інші ключові навички, необхідні для успіху в
  //               галузі IT.
  //             </li>
  //             <li className="mb">
  //               <span className="title-sub">Командна Робота:</span>
  //               <br />
  //               Наші учасники працюють у командах, вчаться ефективно
  //               спілкуватися та координувати свою роботу з іншими. Це важливий
  //               аспект робочого досвіду, який допоможе їм в адаптації до
  //               майбутньої робочої ролі.
  //             </li>
  //             <li className="mb">
  //               <span className="title-sub">Менторство та Навчання:</span>
  //               <br />
  //               Ми надаємо менторську підтримку та навчальні ресурси для
  //               забезпечення успіху кожного учасника та проекту. На кожному
  //               етапі реалізації проекту, наші учасники можуть розраховувати на
  //               підтримку та обратний зв'язок від досвідчених професіоналів
  //               галузі.
  //             </li>
  //             <li className="mb">
  //               <span className="title-sub">Безкоштовний Доступ:</span>
  //               <br />
  //               Ми забезпечуємо безкоштовний доступ до всіх наших проектів та
  //               ресурсів. Наша мета - забезпечити рівні можливості для всіх
  //               талановитих і мотивованих осіб, хто хоче зробити вклад у
  //               соціальний розвиток через технології.
  //             </li>
  //           </ul>
  //           <div className="s-20"></div>
  //           <p className="title-part">
  //             Наші проекти відкривають двері до реального світу IT для тих, хто
  //             готовий працювати над справжніми викликами та робити реальний
  //             вплив на світ. Ми прагнемо створити навчальне середовище, де кожен
  //             учасник може набути цінного досвіду та одночасно сприяти
  //             позитивним соціальним змінам.
  //           </p>
  //         </div>
  //       </div>
  //     </div>
  //     <div className="container">
  //       <div className="row">
  //         <div className="col-lg-12">
  //           <div className="s-50"></div>
  //           <h2 className="title-big tc">Команда та Партнери:</h2>
  //           <div className="s-30"></div>
  //           <p className="title-part">
  //             Наш успіх як організації в значній мірі залежить від зусиль та
  //             відданості нашої команди, а також від підтримки наших партнерів.
  //             Ось деяка інформація про нашу команду та партнерські відносини:
  //           </p>
  //           <div className="s-20"></div>
  //           <ul className="pt-4 pb-4 px-5 r-24 bg-light">
  //             <li className="mb">
  //               <span className="title-sub">Наша Команда:</span>
  //               <br />
  //               Наша команда складається з відданих професіоналів з різних сфер
  //               IT, які об'єднані спільною метою допомогти молодим спеціалістам
  //               знайти своє місце в галузі. Від досвідчених розробників до
  //               талановитих дизайнерів, кожен член нашої команди грає важливу
  //               роль у підтримці нашої місії.
  //             </li>
  //             <li className="mb">
  //               <span className="title-sub">Менторство:</span>
  //               <br />
  //               Ментори - це важлива частина нашої команди. Вони надають цінні
  //               поради та підтримку нашим учасникам, допомагаючи їм вдосконалити
  //               свої навички та рухатися вперед у своїй кар'єрі.
  //             </li>
  //             <li className="mb">
  //               <span className="title-sub">Наші Партнери:</span>
  //               <br />
  //               Ми пишаємося тим, що співпрацюємо з численними організаціями та
  //               компаніями, які ділять наші цінності та підтримують нашу місію.
  //               Наші партнери допомагають нам реалізувати наші проекти, надаючи
  //               необхідні ресурси та підтримку.
  //             </li>
  //             <li className="mb">
  //               <span className="title-sub">Співпраця з Організаціями:</span>
  //               <br />
  //               Ми активно співпрацюємо з іншими організаціями, які працюють над
  //               соціально значущими проектами. Це дозволяє нам об'єднувати
  //               зусилля, обмінюватися досвідом та реалізувати більш амбітні
  //               проекти
  //             </li>
  //             <li className="mb">
  //               <span className="title-sub">Підтримка Громади:</span>
  //               <br />
  //               Ми вдячні за підтримку громади, яка допомагає нам рухатися
  //               вперед і досягати наших цілей. Люди та організації, які
  //               підтримують нас, є важливою частиною нашого успіху.
  //             </li>
  //           </ul>
  //           <div className="s-20"></div>
  //           <p className="title-part">
  //             Ми віримо в силу колективних зусиль та дільних цінностей. Разом ми
  //             можемо допомогти молодим IT-спеціалістам знайти своє місце в цьому
  //             швидко зростаючому світі та одночасно зробити позитивний вплив на
  //             наше суспільство.
  //           </p>
  //         </div>
  //       </div>
  //     </div>
  //     <div className="container">
  //       <div className="row">
  //         <div className="col-lg-12">
  //           <div className="s-50"></div>
  //           <h2 className="title-big tc">Контактна Інформація та Підтримка:</h2>
  //           <div className="s-30"></div>
  //           <p className="title-part">
  //             Ми відкриті для зв'язку та готові надати підтримку та відповіді на
  //             будь-які запитання, що виникають у наших учасників, партнерів, або
  //             будь-кого, хто зацікавлений у нашій діяльності. Ось як ви можете з
  //             нами зв'язатися:
  //           </p>
  //           <div className="s-20"></div>
  //           <ul className="pt-4 pb-4 px-5 r-24 bg-light">
  //             <li className="mb">
  //               <span className="title-sub">Електронна Пошта:</span>
  //               <br />
  //               Ви можете надіслати нам листа на нашу електронну адресу -{' '}
  //               <a href="mailto: admin@topizda.to">admin@topizda.to</a>, і ми
  //               намагатимемося відповісти якнайшвидше.
  //             </li>
  //             <li className="mb">
  //               <span className="title-sub">Телефон:</span>
  //               <br />
  //               Для безпосереднього зв'язку ви можете зателефонувати нам за
  //               вказаним номером.
  //             </li>
  //             <li className="mb">
  //               <span className="title-sub">Форма Зворотнього Зв'язку:</span>
  //               <br />
  //               Також на нашому сайті є форма зворотнього зв'язку, яку ви можете
  //               використати для відправки своїх запитань та коментарів.
  //             </li>
  //             <li className="mb">
  //               <span className="title-sub">Соціальні Мережі:</span>
  //               <br />
  //               Ми присутні в соціальних мережах, де ви можете слідкувати за
  //               нашими оновленнями та зв'язатися з нами.
  //             </li>
  //             <li className="mb">
  //               <span className="title-sub">
  //                 <Link to="/faq">Часті Запитання (FAQ):</Link>
  //               </span>
  //               <br />
  //               Ми розробили розділ Часті Запитання, де можна знайти відповіді
  //               на загальні запитання щодо нашої організації та процесу участі.
  //             </li>
  //             <li className="mb">
  //               <span className="title-sub">Офлайн Зустрічі:</span>
  //               <br />
  //               Ми також організовуємо офлайн зустрічі та заходи, де ви можете
  //               особисто зустрітися з нашою командою та дізнатися більше про
  //               наші проекти та можливості для участі.
  //             </li>
  //             <li className="mb">
  //               <span className="title-sub">Вебінари та Воркшопи:</span>
  //               <br />
  //               Ми регулярно проводимо вебінари та воркшопи, де ви можете
  //               отримати додаткову інформацію, познайомитися з нашою командою та
  //               задати свої запитання.
  //             </li>
  //           </ul>
  //           <div className="s-20"></div>
  //           <p className="title-part">
  //             Ми прагнемо створити зручні канали зв'язку для нашої аудиторії та
  //             партнерів. Ваші запитання та коментарі важливі для нас, і ми
  //             завжди готові надати необхідну інформацію та підтримку.
  //           </p>
  //         </div>
  //       </div>
  //     </div>
  //     <div className="container tc m-bottom">
  //       <div className="s-50"></div>
  //       <h5>Дізнайтеся більше про наші проекти та як ви можете допомогти!</h5>
  //       <div className="s-50"></div>
  //       <a
  //         href="https://form.typeform.com/to/HDTOd2Rb"
  //         className="button"
  //         target="blank"
  //       >
  //         Приєднатися до проекту
  //       </a>
  //     </div>
  //     <div className="s-100"></div>
  //   </section>
  // );
};

export { About };
