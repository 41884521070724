import visions from "../data/visions";

const Vision = () => {
  return (
    <ul className="about-mission">
      {visions.map((vision, index) => (
        <li key={index} className="item-mission" id={vision.id}>
          <img
            src={vision.img}
            alt="Vision"
            loading="lazy"
            className="mission-img mbv"
          ></img> 
          <div className="mission-decor-v">
            <img src={vision.decor} alt="Decor"></img>
          </div>
          <h3 className="mission-title">{vision.title}</h3>
          <p className="mission-text">{vision.text}</p>
        </li>
      ))}
    </ul>
  );
};

export { Vision };
