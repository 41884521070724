function Work() {
  return (
    <section className="works">
      <div className="container flex col">
        <h2 className="title-ass service">How it Works</h2>
        <p className="title-accent pro">
          Collaborate And Get Resources To Succeed
        </p>

        <div className="work_cards">
          <div className="card_text">
            <div className="text-work">
              <h3 className="title-work">
                Join <br />
                The Community
              </h3>
              <p className="text-cont">
                Become part of "Associo" - your path to
                <br /> innovation in law and IT.
                <br />
                Join us to shape the digital future of
                <br /> Ukraine and the whole world together!
                <br />
                Expand your opportunities and cooperate
                <br /> with us!
              </p>
            </div>
          </div>
          <div className="card_photo">
            <img
              className="img_card_photo"
              src={require("../assets/img/works/services.png")}
              alt="img_card2"
            />
          </div>
          <div className="card_text">
            <div className="text-work secondary">
              <h3 className="title-work">Explore Services and Initiatives </h3>
              <p className="text-cont">
                Discover Associo's variety of services and
                <br /> initiatives that support community
                <br /> development and personal growth.
                <br />
                Join our program to advance skills, protect <br />
                rights and support innovation to create a <br />
                better future.
              </p>
            </div>
          </div>
          <div className="card_photo">
            <img
              className="img_card_photo"
              src={require("../assets/img/works/community.png")}
              alt="img_card4"
            />
          </div>
          <div className="card_text">
            <div className="text-work third">
              <h3 className="title-work">Connect <br /> And Collaborate</h3>
              <p className="text-cont">
                Collaborate through Associo with leading
                <br /> legal and IT professionals to deliver today's <br />
                innovations.
                <br />
                Together we will be able to shape the <br />
                future, protect interests, and introduce
                <br /> digital changes in today's digital world!
              </p>
            </div>
          </div>
          <div className="card_photo">
            <img
              className="img_card_photo"
              src={require("../assets/img/works/support.png")}
              alt="img_card6"
            />
          </div>
          <div className="card_text">
            <div className="text-work fourth">
              <h3 className="title-work">Resource Access & Contribution </h3>
              <p className="text-cont">
                Get access to a wide range of important
                <br /> resources and contribute to the
                <br /> development of digital law and IT. Your
                <br /> knowledge, life experience, and
                <br /> professionalism can help shape the future <br />
                of Associo
              </p>
            </div>
          </div>
          <div className="card_photo">
            <img
              className="img_card_photo"
              src={require("../assets/img/works/connect.png")}
              alt="img_card8"
            />
          </div>
          <div className="card_text">
            <div className="text-work fifth">
              <h3 className="title-work">Continuous Growth and Support</h3>
              <p className="text-cont">
                Develop with Associo and receive constant
                <br /> support in your professional growth. Our
                <br /> community provides you with relevant
                <br /> resources, training, and opportunities for <br />
                continuous improvement in the field of
                <br /> digital law, and gaining new experiences.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Work;
