import React from "react";
import { Mission } from "../../components/MissionCard";
import { Vision } from "../../components/VisionCard";
import Work from "../../components/Work";
import Team from "../../components/Team";
import { BaseForm } from "../../components/Forms/BaseForm";
import { MailForm } from "../../components/Forms/MailForm";
import { SubscribeForm } from "../../components/Forms/SubscribeForm";
import { ContactForm } from "../../components/Forms/ContactForm";
import { Studies } from "../../components/Studies";
import { Benefits } from "../../components/Benefits";
import { Reviews } from "../../components/Reviews";
import { Stories } from "../../components/Stories";
import { Services } from "../../components/Services";

const Intro = () => {
  return (
    <>
      <section className="mission">
        <div className="container">
          <h2 className="title-ass">What Associo is</h2>
          <div className="mission-vision">
            <Mission />
            <Vision />
          </div>
        </div>
      </section>
      <Work />
      <section className="form-sect">
        <BaseForm />
      </section>
      <section className="cases">
        <div className="container flex col jcc aic">
          <Studies />
        </div>
      </section>
      <Services />
      <section className="cases">
        <div className="container flex col jcc aic pr">
          <Benefits />
        </div>
      </section>
      <Reviews />
      <section className="form-sect">
        <MailForm />
      </section>
      <Stories />
      <section className="form-sect">
        <SubscribeForm />
      </section>
      <section className="cases">
        <Team />
      </section>

      <section className="form-sect">
        <ContactForm />
      </section>
    </>
  );
};

export { Intro };
