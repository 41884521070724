export default function Tab({ item, tab, setTab, active }) {
  const activeStyle = active
    ? {
        color: "#ffff",
        border: "1px solid #ededed",
        borderRadius: "12px 12px 0px 0px",
      }
    : {};

  return (
    <div
      className="title-prof"
      onClick={() => setTab(item.value)}
      style={activeStyle}
    >
      {item.label}
    </div>
  );
}
