import benefitsFP from "../data/benefitsFP";

const CardBenefitsFP = () => {
  return (
    <ul className="list-benefits">
      {benefitsFP.map((benefit) => (
        <li key={benefit.id} className="item-benefits">
          <img
            src={benefit.img}
            alt="Emodji"
            loading="lazy"
            width="55px"
            height="55px"
            className="img-bnf"
          ></img>
          <h2 className="title-bnf">{benefit.title}</h2>
          <p className="text-bnf">{benefit.text}</p>
        </li>
      ))}
    </ul>
  );
};

export { CardBenefitsFP };
