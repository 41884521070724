import stories from "../data/stories";

const CardBlog = () => {
  return (
    <ul className="flex pa bp">
      {stories.map((story, index) => (
        <div className="blog-thumb">
        <li key={index}>
          <img src={story.img} alt="Pic" loading="lazy" className="case-img"></img>
          <div className="content-case">
          <h3 className="title-case">{story.title}</h3>
          <p lassName="text-case">{story.text}</p>
<div className="btn-blog">  
            <button className="btn-case">{story.buttons.tag1}</button>
            <button className="btn-case">{story.buttons.tag2}</button>
            <button className="btn-case">{story.buttons.tag3}</button>
            <button className="btn-case">{story.buttons.tag4}</button>
            <button className="btn-case">{story.buttons.tag5}</button>
            {/* <button className="btn-case">{story.buttons.tag6}</button> */}
            </div>
           
          <div className="flex jcsb aic">
            <div className="flex aic mb">
            <img
              src={story.avatar}
              alt="Avatar"
              loading="lazy"
              className="avatar"
            ></img>
            <p className="blog-name">{story.name}</p>
            </div>
            <p className="blog-time">{story.time}</p>
          </div>
          <div className="button btn-exp">
          <a href={story.link} >
            Read more
          </a>
          </div>
          </div>
        </li>
        </div>
      ))}
    </ul>
  );
};

export { CardBlog };