import FormExample from "./FormExample";

const SubscribeForm = () => {
  return (
    <div className="container">
      <div className="base-form">
        <h2 className="title-form">Subscribe</h2>
        <p className="form-text">
          Stay informed with our latest news and articles.
        </p>
        <FormExample />
      </div>
    </div>
  );
};

export { SubscribeForm };
