import search from '../assets/img/svg_ass/search.svg';


const Search = () => {
  
  
  return (
    <form autoComplete="off" >
      <button type="submit" className="icon-wrapper">
          <img src={search} alt='search' width={24} height={24}></img>
      </button>
      <input
      type="search"
      name="search"
             />
    </form>
  );
};

export { Search };
