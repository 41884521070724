import React from 'react';
import logotype from '../assets/img/svg_ass/logo.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const NavPages = ( ) => {

  return (
    <header className='w-100'>
      <div className='container'>
        <header className="flex jcsb aic h-10 py-5">
          <h1 id="logo">
            <a href='/' aria-label="ASSOCIO"><LazyLoadImage src={logotype} width={137} height={63} alt="ASSOCIO" /></a>
          </h1>
          <div className="flex jcfe aic mobile">
            <a href="/" className='mx-4' aria-label="Home">Home</a>
          </div>
        </header>
      </div>
    </header>
  )
}

export {NavPages}
