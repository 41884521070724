import Decor from "../assets/img/svg_ass/decor.svg";
import Mission from "../assets/img/about/mission.png";


const missions = [
  {
    id: 1,
    title: "Mission",
    text: "We are a non-profit enterprise. We strive to revolutionize the global market via digital innovation. We strive to integrate cutting-edge tech in law and IT, meeting today's economic demands. Supporting worldwide legal professionals, educators, and students, we foster legal aid, advise on IT market defense, and cryptocurrency.",
    img: Mission,
    decor: Decor,
    }
  ];

export default missions;
