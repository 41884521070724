import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Review } from "../elements/Review";
import testimonails from "../data/testimonails";

const Reviews = () => {
  const Slide = () => {
    var settings = {
      autoplay: false,
      dots: true,
      arrows: false,
      infinite: true,
      pauseOnHover: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };
    return (
      <section id="reviews" className="mx-5">
        <Slider {...settings}>
          {testimonails.map((e, index) => (
            <Review
              key={index}
              pic={e.avatar}
              name={e.name}
              email={e.mail}
              text={e.text}
              etc={e.etc}
            />
          ))}
        </Slider>
      </section>
    );
  };

  return (
    <section id="reviews" className="cases">
      <div className="container">
      <h2 className="title-ass mbb">Testimonials</h2>
      <h3 className="title-accent text-capitalize mis">Discover Insights from Our Valued Customers!</h3>
      <Slide />
      </div>
      
    </section>
  );
};

export { Reviews };
