import { useState } from "react";
import Form from "react-bootstrap/Form";

function FormExample() {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      className="mail-form"
    >
      <Form.Group controlId="validationCustom02">
        <Form.Label>
          <Form.Control
            type="email"
            placeholder="Email*"
            className="contact-input"
            aria-describedby="inputGroupPrepend"
            required
          />
        </Form.Label>
        <Form.Control.Feedback type="invalid">
          Please choose a email.
        </Form.Control.Feedback>
      </Form.Group>
      <div className="button btn-hero tc mgh">
        <a
          href="https://form.typeform.com/to/nykgTtwJ#role_name=participant"
          target="blank"
        >
          Join Us
        </a>
      </div>
    </Form>
  );
}

export default FormExample;
