import { CardServices } from "../elements/CardServices";
const Services = () => {
  return (
    <>
      <section className="services-section">
        <div className="container flex col">
          <h2 className="title-ass service">Featured services</h2>
          <h3 className="title-accent pro text-capitalize">
            Discover premium solutions for your digital needs
          </h3>
          <CardServices />
        </div>
      </section>
    </>
  );
};

export { Services };
