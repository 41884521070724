const BaseForm = () => {
  return (
    <div className="container">
      <div className="base-form">
        <h2 className="title-form">Join the associo</h2>
        <p className="form-text">
          Unlock exclusive benefits and opportunities with Associo
        </p>
        <div className="button btn-hero tc">
        <a
          href="https://form.typeform.com/to/nykgTtwJ#role_name=participant"
          target="blank"
          
        >
          Join Us
        </a>
        </div>
      </div>
    </div>
  );
};

export { BaseForm };
