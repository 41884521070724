import Logo from '../assets/img/svg_ass/logo_min.svg';

const banners = [
  {
    image: Logo,
    title: "For Lawyers",
    text: "Get benefits from the professional self-governance in digital law​​.",
    number: "12056",
    users: "Users",
  },
  {
    image: Logo,
    title: "For Crypto Users",
    text: "Protect crypto accounts and wallets from PennyWise virus and similar threatset benefits from the professional self-governance in digital law​​.",
    number: "305",
    users: "Security specialists",
  },
  {
    image: Logo,
    title: "For Students",
    text: "Join to receive education in the field of digitalization of services",
    number: "10",
    users: "New programs launched",
  },
  {
    image: Logo,
    title: "For Artists",
    text: "Get benefits from tGet intellectual property protection to prevent unauthorized use of your work​​​​.",
    number: "124",
    users: "Lawyers",
  },
  {
    image: Logo,
    title: "For Internet Users",
    text: "Get cyberbullying and harassment advice and how to navigate the internet​​.",
    number: "305",
    users: "IT specialists on platform",
  },
  {
    image: Logo,
    title: "For Residents",
    text: "Get a notary servise online​.",
    number: "124",
    users: "Lawyers",
  },
  {
    image: Logo,
    title: "For IT Experts",
    text: "Get access to thousands of customers around the world",
    number: "12056",
    users: "Users",
  },
  {
    image: Logo,
    title: "For Enterpreneurs",
    text: "Get guidance and support in registering and operating of organization in Ukraine​.",
    number: "124",
    users: "Lawyers on platform",
  },
];

export default banners;
