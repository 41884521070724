import services from "../data/services";

const CardServices = () => {
  return (
    <ul className="list-service">
      {services.map((service, index) => (
        <div className="card-item">
          <li key={index} className="flex gp-24">
            <div>
              <h3 className="title-service">{service.title}</h3>
              <p className="text-serv">{service.text}</p>
              <div className="button btn-hero cards">
                <a href={service.link}>Get Advice</a>
              </div>
            </div>
            <img
              src={service.img}
              alt="Service"
              loading="lazy"
              className="img-serv"
            ></img>
          </li>
        </div>
      ))}
    </ul>
  );
};

export { CardServices };
