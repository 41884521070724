import Globe from "../assets/img/benefits/globe.png";
import Microscope from "../assets/img/benefits/microscope.png";
import Books from "../assets/img/benefits/books.png";
import Bonnet from "../assets/img/benefits/bonnet.png";
import Face from "../assets/img/benefits/face.png";
import Hands from "../assets/img/benefits/hands.png";

const benefitsFU = [
  {
    id: 7,
    img: Globe,
    title: "Global communication:",
    text: "Find like-minded people and partners around the world.",
  },
  {
    id: 8,
    img: Bonnet,
    title: "Career opportunities:",
    text: "Find new and innovative ways for your own successful career growth.",
  },
  {
    id: 9,
    img: Microscope,
    title: "Expert knowledge:",
    text: "Consultations with leading experts in various fields.",
  },
  {
    id: 10,
    img: Hands,
    title: "Exclusive content:",
    text: "Access to unique materials, courses and webinars.",
  },
  {
    id: 11,
    img: Books,
    title: "Innovative solutions:",
    text: "Get access to advanced technologies and innovations.",
  },
  {
    id: 12,
    img: Face,
    title: "Professional support:",
    text: "Support and advice from a community of professionals.",
  },
];

export default benefitsFU;
