import Vision from "../assets/img/about/vision.png";
import Decor from "../assets/img/svg_ass/decor.svg";

const visions = [
    {
        id: 2,
        title: "Vision",
        text: "We aspire to a future where access to legal services and IT innovation is simple and universal, opening the door to new opportunities for citizens around the world. Our goal is to become a leader in digital integration, improving every aspect of life through technology. Associo aims to create a society where digitalization is the key to progress.",
        img: Vision,
        decor: Decor,
      }
]

export default visions; 