import React from 'react';
import teamData from '../data/teams'; 

function Team() {
  return (
    <>
      <div className='container flex col jcc aic pr'>
        <h2 className="title-ass mbb">Dream Team</h2>
        <h3 className="title-accent text-capitalize mis">Get to know the talented individuals behind our success</h3>
      <ul className="teams_cards">
        {teamData.map((team, index) => (
          <li className="team_card" key={index}>
            <div className='team-img'>
            <img  width={200} height={200} src={team.image} alt="img_team" className='team-ass-img'/>
            </div>
            <div className='team-content'>
            <h4>{team.title}</h4>
            <p>{team.text}</p>
            </div>
            
          </li>
        ))}
      </ul>
      <img className="mission-decor-m" src={require('../assets/img/team_ass/decor2.svg').default} alt="decor1" />
      <img className="mission-decor-t" src={require('../assets/img/team_ass/decor2.svg').default} alt="decor2" />
      </div>
    </>
  );
}

export default Team;
