import Pic1 from '../assets/img/studies/pic1.png';
import Pic2 from '../assets/img/studies/pic2.png';
import Pic3 from '../assets/img/studies/pic3.png';
import Pic4 from '../assets/img/studies/pic4.png';

const studies = [
  {
    img: Pic1,
    title: "From Tilda to WordPress",
    text: "Our mission is to empower businesses and individuals by seamlessly transitioning their...",
    buttons: {
      tag1: "Website",
      tag2: "WordPress",
      tag3: "Integration",
      tag4: "Design",
      tag5: "Optimizaton",
      tag6: "Seo"
    },
    link: "https://form.typeform.com/to/nykgTtwJ#role_name=participant",
    icon: "To right"
  },
  {
    img: Pic2,
    title: "Online Notary Services Made Easy",
    text: "E-notarius is your convenient online notary, ready to assist you with just a click. Find an i...",
    buttons: {
      tag1: "Notary Services",
      tag2: "E-Notarius",
      tag3: "Legal Documents",
      tag4: "Electronic Notarization",
      tag5: null,
      tag6: null
    },
    link: "https://form.typeform.com/to/nykgTtwJ#role_name=participant",
    icon: "To right"
  },
  {
    img: Pic3,
    title: "Exporting Artworks Abroad",
    text: "Determine the cultural value of your artworks first, as it will dictate the specific doc...",
    buttons: {
      tag1: "Art",
      tag2: "International",
      tag3: "Documentation",
      tag4: "Export paintings",
      tag5: "Art gallery",
      tag6: null
    },
    link: "https://form.typeform.com/to/nykgTtwJ#role_name=participant",
    icon: "To right"
  },
  {
    img: Pic4,
    title: "Protect Intellectual Property",
    text: "Get assistance in safeguarding your intellectual assets on the internet.",
    buttons: {
      tag1: "Legal",
      tag2: "Trademarks",
      tag3: "Copyraght",
      tag4: "Patents",
      tag5: null,
      tag6: null

    },
    link: "https://form.typeform.com/to/nykgTtwJ#role_name=participant",
    icon: "To right"
  },
];

export default studies;
