import { CardCases } from "../elements/CardCases";

const Studies = () => {
  return (
    <>
      <div className="studies">
        <h2 className="title-ass mnl">Case Studies</h2>
        <div className="button btn-cases">
          <a href="/cases" target="blank">
            All Cases
          </a>
        </div>
      </div>
      <h3 className="title-accent mis">Real-World Success Stories Unveiled</h3>
      <CardCases />
    </>
  );
};

export { Studies };
