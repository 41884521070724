import React from "react";
import { Nav } from "../components/Nav";
import { Footer } from "../components/Footer";
import { Outlet } from "react-router-dom";
import { Hero } from "../components/Hero";
import Banner from "../components/Banner";


const Home = () => {
  return (
    <>
      <Nav />
      <Hero />
      <Banner />
      <Outlet />
      <Footer />
    </>
  );
};

export { Home };
