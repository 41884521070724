import FormExample from "./FormExample";

const MailForm = () => {
  return (
    <div className="container">
      <div className="base-form">
        <h2 className="title-form">Join the associo</h2>
        <p className="form-text">
          Unlock exclusive benefits and opportunities with Associo
        </p>
          <FormExample />
      </div>
    </div>
  );
};

export { MailForm };
