import Avatar1 from "../assets/img/testim/avatar1.png";
import Avatar2 from "../assets/img/testim/avatar2.png";
import Avatar3 from "../assets/img/testim/avatar3.png";
import Avatar4 from "../assets/img/testim/avatar4.png";
import Plus from "../assets/img/svg_ass/plus.svg";
import Minus from "../assets/img/svg_ass/logo_min.svg";

const testimonails = [
  {
    avatar: Avatar1,
    name: "Alexander Schmidt",
    mail: "@AlexSchmidtEU",
    button: {
      svg1: Plus,
      svg2: Minus,
    },
    text: "I recently consulted Associo for copyright protection advice as a blogger. The experts provided invaluable insights tailored to my... ",
    etc: 'needs, covering copyright registration, policy creation, and enforcement strategies. I highly recommend Associo for their expertise in protecting intellectual property online.',
  },
  {
    avatar: Avatar2,
    name: "Alex Johnson",
    mail: "@Alex Johnson",
    button: {
      svg1: Plus,
      svg2: Minus,
    },
    text: "I recently used Dvostok's services to transfer my website from Tilda to WordPress, and I must say it was a seamless experience. The team was... ",
    etc: 'professional, efficient, and delivered exceptional results. Highly recommended for anyone looking to make the switch hassle-free!',
  },
  {
    avatar: Avatar3,
    name: "Marta",
    mail: "@lovemarta",
    button: {
      svg1: Plus,
      svg2: Minus,
    },
    text: "I found the consultation on exporting paintings abroad to be extremely informative and detailed. It started with assessing the cultural value of my... ",
    etc: 'paintings, which was crucial for understanding the required documentation. The study also explained the process of conducting a state expert examination, including costs and timelines. This consultation greatly enhanced my understanding of the export procedures and ensured that I comply with all relevant regulations.'
  },
  {
    avatar: Avatar4,
    name: "Chen Mei Ling",
    mail: "@MeiLingProfessional",
    button: {
      svg1: Plus,
      svg2: Minus,
    },
    text: "I used Associo's online notary services and was impressed with the efficient process. The platform's user-friendly interface made it easy to upload and... ",
    etc: "share documents securely. The notary service was prompt, and I received the notarized documents quickly. Overall, Associo's online notary service exceeded my expectations, and I highly recommend it.",
  },
];

export default testimonails;
