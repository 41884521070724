import benefitsFU from "../data/benefitsFU"

const CardBenefitsFU = () => {
return (
    <ul className="list-benefits">
        {benefitsFU.map((benefit) => 
    <li key={benefit.id} className="item-benefits">
        <img
        src={benefit.img}
        alt="Emodji"
        loading="lazy"
        className="img-bnf"
        ></img>
        <h2 className="title-bnf">{benefit.title}</h2>
        <p className="text-bnf">{benefit.text}</p>
    </li>
    )}
    </ul>
)
}

export { CardBenefitsFU }