import Avatar1 from "../assets/img/team_ass/Yuliya.jpg";
import Avatar2 from "../assets/img/team_ass/Inna.jpg";
import Avatar3 from "../assets/img/team_ass/Yuriy.jpg";
import Avatar4 from "../assets/img/team_ass/Oleksandr.jpg";

const teams = [
  {
    image: Avatar1,
    title: "Julia Rubanska",
    text: "​Product designer",
  },
  {
    image: Avatar2,
    title: "Inna Zorina",
    text: "​Frontend Developer",
  },
  {
    image: Avatar3,
    title: "Yura Romanenko",
    text: "​Frontend Developer",
  },
  {
    image: Avatar4,
    title: "Alex Gurinenko",
    text: "​Frontend Developer",
  },
  {
    image: Avatar1,
    title: "Julia Rubanska",
    text: "​Product designer",
  },
  {
    image: Avatar2,
    title: "Inna Zorina",
    text: "​Frontend Developer",
  },
  {
    image: Avatar3,
    title: "Yura Romanenko",
    text: "​Frontend Developer",
  },
  {
    image: Avatar4,
    title: "Alex Gurinenko",
    text: "​Frontend Developer",
  },
];

export default teams;
