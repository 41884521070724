import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import logotype from "../assets/img/svg_ass/logo_max.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Search } from "./Search";
import ArrowUp from "../assets/img/svg_ass/arrowUp.svg";

const Nav = () => {
  const [opened, setOpened] = useState(true);

  return (
    <header className="w-100 bg-lightgrey sh">
      <div className="container">
        <header className="flex jcsb aic h-10 py-5" id="header">
          <h1 id="logo">
            <a href="/" aria-label="ASSOCIO">
              <LazyLoadImage
                src={logotype}
                width={137}
                height={63}
                alt="ASSOCIO"
              />
            </a>
          </h1>
          <div
            className={opened ? "burger" : "burger open"}
            onClick={() => setOpened(!opened)}
          >
            <span className="tt"></span>
            <span className="mm"></span>
            <span className="bb"></span>
          </div>
          <div className={opened ? "menu activeMenu" : "menu"}>
            <NavLink
              to="/"
              className="mx-2 px-1"
              onClick={() => setOpened(!opened)}
            >
              Home
            </NavLink>
            <NavLink
              to="about"
              className="mx-2 px-1"
              onClick={() => setOpened(!opened)}
            >
              About
            </NavLink>
            <NavLink
              to="membership"
              className="mx-2 px-1"
              onClick={() => setOpened(!opened)}
            >
              Membership
            </NavLink>
            <NavLink
              to="services"
              className="mx-2 px-1"
              onClick={() => setOpened(!opened)}
            >
              Services
            </NavLink>
            <NavLink
              to="cases"
              className="mx-2 px-1"
              onClick={() => setOpened(!opened)}
            >
              Cases
            </NavLink>
            <NavLink
              to="blog"
              className="mx-2 px-1"
              onClick={() => setOpened(!opened)}
            >
              Blog
            </NavLink>
            <NavLink
              to="faq"
              className="mx-2 px-1"
              onClick={() => setOpened(!opened)}
            >
              FAQ
            </NavLink>
          </div>
          <div className="flex jcsa tc">
            <Search />
            <a
              href="https://form.typeform.com/to/nykgTtwJ#role_name=participant"
              target="blank"
              className="button btn-header"
            >
              Join Us
            </a>
          </div>
          <a href="#header" className="arrow-up">
            <img src={ArrowUp} alt="ArrowUp" width="16px" height="12px"></img>
          </a>
        </header>
      </div>
    </header>
  );
};

export { Nav };
