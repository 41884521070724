import React from 'react';
import DecorElement from '../../elements/DecorElement';

function FAQ() {
  return (
    <section id="top">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="s-20"></div>
            <DecorElement />
            <div className="s-60"></div>
            <h2 className="mb-3 title-big">Часті Запитання (FAQ)</h2>
            <div className="s-30"></div>
            <ol>
              <li className="title-sub">
                <h4 className="title-sub">Що таке ГО "ТопИздаТо"?</h4>
                <p className="title-part">
                  ГО "ТопИздаТо" - це громадська організація, яка допомагає
                  молодим IT-спеціалістам набути практичного досвіду через
                  участь у реальних соціально значущих проектах. Наша мета -
                  забезпечити учасникам необхідний досвід, щоб вони могли знайти
                  свою першу роботу в галузі.
                </p>
                <div className="s-20"></div>
              </li>
              <li className="title-sub">
                <h4 className="title-sub">Де розташована ваша організація?</h4>
                <p className="title-part">
                  Наша організація знаходиться за адресою: Україна, Одеська
                  обл., місто Одеса, вул.Старопортофранківська, будинок 107,
                  квартира 21.
                </p>
                <div className="s-20"></div>
              </li>
              <li className="title-sub">
                <h4 className="title-sub">Як я можу з вами зв'язатися?</h4>
                <p className="title-part">
                  Ви можете зв'язатися з нами через контактну форму на нашому
                  сайті.
                </p>
                <div className="s-20"></div>
              </li>
              <li className="title-sub">
                <h4 className="title-sub">Як я можу стати учасником вашої організації?</h4>
                <p className="title-part">
                  Щоб стати учасником, вам потрібно заповнити форму на нашому
                  сайті, після чого ми зареєструємо вас в нашому Slack. Важливо
                  мати базові навички в IT та бажання набути практичного
                  досвіду.
                </p>
                <div className="s-20"></div>
              </li>
              <li className="title-sub">
                <h4 className="title-sub">Чи потрібно оплачувати участь у вашій організації?</h4>
                <p className="title-part">
                  Ні, участь у нашій організації абсолютно безкоштовна.
                </p>
                <div className="s-20"></div>
              </li>
              <li className="title-sub">
                <h4 className="title-sub">Як ви обираєте проекти для учасників?</h4>
                <p className="title-part">
                  Ми співпрацюємо з різними громадськими організаціями, яким
                  потрібна IT-підтримка, і обираємо проекти, які мають соціальну
                  значущість.
                </p>
                <div className="s-20"></div>
              </li>
              <li className="title-sub">
                <h4 className="title-sub">Який термін участі в проектах?</h4>
                <p className="title-part">
                  Термін участі в проектах варіюється від 6 до 18 місяців.
                </p>
                <div className="s-20"></div>
              </li>
              <li className="title-sub">
                <h4 className="title-sub">Який досвід я отримаю під час участі в проектах?</h4>
                <p className="title-part">
                  Під час участі в проектах ви отримаєте практичний досвід, який
                  допоможе вам знайти роботу в IT-галузі після завершення
                  18-місячного терміну.
                </p>
                <div className="s-20"></div>
              </li>
              <li className="title-sub">
                <h4 className="title-sub">Як я можу отримати підтримку або консультації?</h4>
                <p className="title-part">
                  Ви можете отримати підтримку та консультації в нашому Slack
                  каналі.
                </p>
                <div className="s-20"></div>
              </li>
              <li className="title-sub">
                <h4 className="title-sub">Як ви вимірюєте успіх проектів та учасників?</h4>
                <p className="title-part">
                  Основним показником успіху є кількість учасників, яким ми
                  допомогли знайти роботу.
                </p>
                <div className="s-20"></div>
              </li>
              <li className="title-sub">
                <h4 className="title-sub">Чи можу я стати ментором у вашій організації?</h4>
                <p className="title-part">
                  Так, ми завжди раді вітати досвідчених професіоналів як
                  менторів.
                </p>
                <div className="s-20"></div>
              </li>
              <li className="title-sub">
                <h4 className="title-sub">Як я можу підтримати вашу організацію?</h4>
                <p className="title-part">
                  Ви можете розповісти про нас своїм знайомим та колегам, а
                  також в соціальних мережах, щоб привернути увагу до нашої
                  діяльності та залучити більше учасників та партнерів.
                </p>
                <div className="s-20"></div>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </section>
  );
}

export { FAQ };
