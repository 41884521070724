import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "./pages/Home";
import { Intro } from "./pages/parts/Intro";
import { Privacy } from "./pages/Privacy";
import { FAQ } from "./pages/parts/FAQ";
import "./styles/custom.sass";
import { About } from "./pages/parts/About";
import { Membership } from "./pages/Membership";
import { Services } from "./pages/Services";
import { Cases } from "./pages/Cases";
import { Blog } from "./pages/Blog";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}>
          <Route index element={<Intro />} />
          <Route path="about" element={<About />} />
          <Route path="faq" element={<FAQ />} />
        </Route>
        <Route path="membership" element={<Membership />} />
        <Route path="services" element={<Services />} />
        <Route path="cases" element={<Cases />} />
        <Route path="blog" element={<Blog />} />
        <Route path="privacy" element={<Privacy />} />
      </Routes>
    </BrowserRouter>
  );
}

export { App };
