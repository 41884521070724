import Pic1 from '../assets/img/stories/img1.png';
import Pic2 from '../assets/img/stories/img2.png';
import Pic3 from '../assets/img/stories/img3.png';
import Avatar1 from '../assets/img/testim/avatar4.png';
import Avatar2 from '../assets/img/testim/avatar3.png';
import Avatar3 from '../assets/img/testim/avatar2.png';


const stories = [
  {
    img: Pic1,
    title: "Cyberbullying and Harassment",
    text: "Cyberbullying or harassment - a modern form of crime on the Internet. How to protect yourself and your children from per...",
    buttons: {
      tag1: "Internet Crimes",
      tag2: "Cybersecurity",
      tag3: "Cyberbullying",
      tag4: "Online Safety",
      tag5: "Online Behavior",
      tag6: "Protection"
    },
    avatar: Avatar1,
    name: "M. Riapolova",
    time: "Jan 10, 2024",
    link: "Read more",
    icon: "To right",
  },
  {
    img: Pic2,
    title: "Payment Procedure: Dealing with Water Utility Letters",
    text: "n the article, we will discuss the steps that consumers of water supply and wastewater services are required to take as of today in...",
    buttons: {
      tag1: "Water Utility",
      tag2: "Utility Bills",
      tag3: "Water Charges",
      tag4: "Municipal Services",
      tag5: "Government Services",
      tag6: " "
    },
    avatar: Avatar2,
    name: "S. Yarova",
    time: "March 21, 2024",
    link: "Read more",
    icon: "To right",
  },
  {
    img: Pic3,
    title: "Exporting Artworks Without Certification",
    text: "First of all, it is necessary to determine whether your paintings are of cultural value - the list of necessary documents will depend...",
    buttons: {
      tag1: "Internet Crimes",
      tag2: "Cybersecurity",
      tag3: "Cyberbullying",
      tag4: "Online Safety",
      tag5: "Online Behavior",
      tag6: "Protection"
    },
    avatar: Avatar3,
    name: "J. Sallivan",
    time: "Feb 21, 2024",
    link: "Read more",
    icon: "To right",
  },
];

export default stories;
