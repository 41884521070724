import heroImg from "../assets/img/hero/hero.webp";

const Hero = () => {
  return (
    <div className="container flex jcsb mbh">
      <div className="hero">
        <h1 className="title-hero">
          Your Partner in Legal <br /> and Technological <br /> Excellence
        </h1>
        <p className="hero-text text-capitalize">
          Are you in need of legal assistance or <br /> ready to offer it
          yourself?{" "}
        </p>
        <div className="bh flex mg">
          <div className="button btn-hero">
          <a
            href="https://form.typeform.com/to/nykgTtwJ#role_name=participant"
            target="blank"
          >
            Join Us
              </a>
              </div>
              <div className="button btn-hero serv">
          <a
            href="https://form.typeform.com/to/nykgTtwJ#role_name=participant"
            target="blank"
            
          >
            Order Service
                </a>
                </div>
        </div>
      </div>

      <img src={heroImg} alt="associo"></img>
    </div>
  );
};

export { Hero };
