import { useState } from "react";
import Tabs from "./Tabs/Tabs";
import { CardBenefitsFP } from "../elements/CardBenefitsFP";
import { CardBenefitsFU } from "../elements/CardBenefitsFU";
import Decor from "../assets/img/svg_ass/decor.svg";

const Benefits = () => {
  const [tab, setTab] = useState("cardBenefitsFP");

  const tabs = [
    {
      label: "Benefits For Professionals",
      value: "cardBenefitsFP",
    },
    {
      label: "Benefits For Users",
      value: "cardBenefitsUP",
    },
  ];

  function renderTabContent() {
    switch (tab) {
      case "cardBenefitsFP": {
        return (
          <div className="wrapper-bnf">
            <CardBenefitsFP />
          </div>
        );
      }
      case "cardBenefitsUP": {
        return (
          <div className="wrapper-bnf">
            <CardBenefitsFU />
          </div>
        );
      }
      default: {
        return (
          <div className="wrapper-bnf">
            <CardBenefitsFP />
          </div>
        );
      }
    }
  }

  return (
    <>
      <h2 className="title-ass mbb">Membership Benefits</h2>
      <h3 className="title-accent text-capitalize mis">
        Expand your horizons with a global community of experts
      </h3>
      <Tabs
        tabs={tabs}
        renderTabContent={renderTabContent}
        tab={tab}
        setTab={setTab}
      />
      <div className="bnf-decor">
        <img src={Decor} alt="Decor"></img>
      </div>
    </>
  );
};

export { Benefits };
