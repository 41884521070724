import banners from "../data/banners";

function Baner() {
  const renderBanners = () => {
    return banners.map((banner, index) => (
      <li key={index} className="item">
        <img src={banner.image} alt="Logo" loading="lazy"></img>
        <h3 className="banner-title">{banner.title}</h3>
        <p className="banner-text">{banner.text}</p>
        <div className="banner-detal">
        <span className="sp-num">{banner.number}</span>
        <span className="sp-us">{banner.users}</span>
        </div>
        
      </li>
    ));
  };

  const renderMovingLineContainers = (count) => {
    const movingLineContainers = [];
    for (let i = 0; i < count; i++) {
      movingLineContainers.push(
        <div className="mov-lin-cont mbt" key={i}>
          <ul className="mov-line" aria-hidden={i > 0}>{renderBanners()}</ul>
        </div>
      );
    }
    return movingLineContainers;
  };

  return <div className="banner-container">{renderMovingLineContainers(2)}</div>;
}

export default Baner;
