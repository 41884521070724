import Tab from "./Tab";

export default function Tabs({ tabs = [], renderTabContent, tab, setTab }) {
  return (
    <>
      <div className="tab-wrapper">
        {tabs.map((item, index) => {
          return (
            <Tab
              key={index}
              item={item}
              tab={tab}
              setTab={setTab}
              active={tab === item.value}
            />
          );
        })}
      </div>
      <div>{renderTabContent()}</div>
    </>
  );
}
