import React, { useState } from "react";

const Review = (props) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="px-3 tc card">
      <div className="flex aic gp mb-24">
        <div>
          <img src={props.pic} alt={props.name} />
        </div>
        <div className="flex col aifs jcfe gp-4">
          <h4 className="title-part mgh">{props.name}</h4>
          <p className="card-mail mgh">{props.email}</p>
        </div>
      </div>

      <div className="accordion">
        <div className="accordion-content">
          <p className="tl exc mgh">{props.text}</p>
          <div className="tab-plus" onClick={() => setIsActive(!isActive)}>
            {isActive ? "-" : "+"}
          </div>
        </div>
        {isActive && (
          <div className="accordion-content">
            {<p className="tl exc">{props.etc}</p>}
          </div>
        )}
      </div>
    </div>
  );
};

export { Review };
