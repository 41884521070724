import studies from "../data/studies";

const CardCases = () => {
  return (
    <ul className="flex pa bp">
      {studies.map((studie, index) => (
        <div className="card-thumb">
          <li key={index}>
            <img
              src={studie.img}
              alt="Studies"
              loading="lazy"
              className="case-img"
            ></img>
            <div className="content-case">
              <h3 className="title-case">{studie.title}</h3>
              <p className="text-case">{studie.text}</p>
              <div className="btn-group">
                <button className="btn-case">{studie.buttons.tag1}</button>
                <button className="btn-case">{studie.buttons.tag2}</button>
                <button className="btn-case">{studie.buttons.tag3}</button>
                <button className="btn-case">{studie.buttons.tag4}</button>
                {/* <button className="btn-case">{studie.buttons.tag5}</button>
                <button className="btn-case">{studie.buttons.tag6}</button> */}
              </div>
              <div className="button btn-exp">
              <a href={studie.link} >
                Explore
              </a>
              </div>
            </div>
          </li>
        </div>
      ))}
    </ul>
  );
};

export { CardCases };
