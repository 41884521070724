import Mail from "../assets/img/svg_ass/mail.svg";
import Fone from "../assets/img/svg_ass/fono.svg";
import Maps from "../assets/img/svg_ass/map-mini.svg";
const Footer = () => {
  return (
    <footer className="bg-light-custom">
      <div className="container flex jcsb">
        <div className="navigation">
          <a href="/about" className="footer-link">
            About
          </a>
          <a href="/membership" className="footer-link">
            Membership
          </a>
          <a href="/services" className="footer-link">
            Servises
          </a>
          <a href="/blog" className="footer-link">
            Blog
          </a>
          <a href="/faq" className="footer-link">
            FAQ
          </a>
        </div>
        <div className="contact">
          <h4 className="contact-title">Contact Us</h4>
          <div className="contact-link">
            <img src={Mail} alt="Mail" className="contact-icon"></img>
            <a href="mailto:support@associo.org.ua" className="footer-contact_link">support@associo.org.ua</a>
          </div>
          <div className="contact-link">
            <img src={Fone} alt="Fone" className="contact-icon"></img>
            <a href="tel:+3 080 035 7238" className="footer-contact_link">+3 080 035 7238</a>
          </div>
          <div className="contact-link">
            <img src={Maps} alt="Maps" className="contact-icon"></img>
            <a href="https://associo.org.ua" className="footer-contact_link">associo.org.ua</a>
          </div>
        </div>
      </div>
      {/* <div className="row">
          <div className="col-lg-4 tr">
            <p>ГРОМАДСЬКА ОРГАНІЗАЦІЯ "ТопИздаТо"<br />
            ІПН: 44894768</p>
          </div>
        </div> */}

      {/* <div className="row">
          <div className="col-lg-8">
            <p>@{year.getFullYear()} - Topizdato. Усі права захищено</p>
          </div>
          <div className="col-lg-4 tr">
            <a href='/privacy' aria-label="Політика конфіденційності">Політика конфіденційності</a>
          </div>
        </div> */}

      <div className="container2 ">
        <div className="Associo">
          <p>
            © Associo, 2024. Public ogranization “Association for digitalization
            of law". All Rights Reserved
          </p>
        </div>
        <div className="footer-privacy">
          <a href="/privacy" aria-label="Privacy Policy">
            Privacy Policy
          </a>
          <a href="/privacy" aria-label="Cookies Policy">
            Cookies Policy
          </a>
          <a href="/privacy" aria-label="Terms of service">
            Terms of service
          </a>
        </div>
      </div>
    </footer>
  );
};

export { Footer };
