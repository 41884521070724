import { useState } from "react";
import mapForm from "../../assets/img/svg_ass/map.svg";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

function TextControlsExample() {
  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    setErrorMessage("Invalid email format");
    
    form.reset();
  };

  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      className="contact-form"
    >
      <Form.Group className="flex col gp" controlId="exampleForm.ControlInput1">
        <Form.Label htmlFor="inlineFormInputName" visuallyHidden></Form.Label>
        <Form.Control
          id="inlineFormInputName"
          placeholder="Name*"
          required
          className="contact-input"
        />
        <Form.Label>
          <Form.Control
            type="email"
            placeholder="Email*"
            className="contact-input"
            required
            pattern="/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i"
          />
          <div>{errorMessage && <p className="error"> {errorMessage} </p>}</div>
        </Form.Label>
        <Form.Label>
          <Form.Control
            as="textarea"
            rows={7}
            className="contact-input comment"
            placeholder="Comment*"
          />
        </Form.Label>
        <Form.Text className="contact-text">
          By submitting your information, you agree to the Associo`s <br />
          Terms of Service and Privacy Policy. You can opt out at any <br />
          time.
        </Form.Text>
        <Button type="submit" className="button btn-submit">
          Send
        </Button>
      </Form.Group>
    </Form>
  );
}

const ContactForm = () => {
  return (
    <div className="container">
      <div className="thumb-contact">
        <img src={mapForm} alt="Map" width="640px" height="600px"></img>
        <div className="contact-grup">
          <h2 className="title-contact">Contact Us</h2>
          <TextControlsExample />
        </div>
      </div>
    </div>
  );
};

export { ContactForm };
